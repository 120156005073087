import React from 'react';
import { useTranslation } from "react-i18next";

function TimezoneSelectNoGuess({timeZone, setTimeZone}) {
  const { t } = useTranslation();
  const timezones = [
    { value: "US/Pacific", label: t("generic.timezone_pacific") },
    { value: "US/Eastern", label: t("generic.timezone_eastern") },
    { value: "US/Mountain", label: t("generic.timezone_mountain") },
    { value: "US/Central", label: t("generic.timezone_central") },
    { value: "PRC", label: t("generic.timezone_prc") },
    { value: "Etc/GMT+12", label: t("generic.timezone_utc+12") },
    { value: "Etc/GMT+11", label: t("generic.timezone_utc+11") },
    { value: "Etc/GMT+10", label: t("generic.timezone_utc+10") },
    { value: "Etc/GMT+9", label: t("generic.timezone_utc+9") },
    { value: "Etc/GMT+8", label: t("generic.timezone_utc+8") },
    { value: "Etc/GMT+7", label: t("generic.timezone_utc+7") },
    { value: "Etc/GMT+6", label: t("generic.timezone_utc+6") },
    { value: "Etc/GMT+5", label: t("generic.timezone_utc+5") },
    { value: "Etc/GMT+4", label: t("generic.timezone_utc+4") },
    { value: "Etc/GMT+3", label: t("generic.timezone_utc+3") },
    { value: "Etc/GMT+2", label: t("generic.timezone_utc+2") },
    { value: "Etc/GMT+1", label: t("generic.timezone_utc+1") },
    { value: "Etc/GMT", label: t("generic.timezone_utc") },
    { value: "Etc/GMT-1", label: t("generic.timezone_utc-1") },
    { value: "Etc/GMT-2", label: t("generic.timezone_utc-2") },
    { value: "Etc/GMT-3", label: t("generic.timezone_utc-3") },
    { value: "Etc/GMT-4", label: t("generic.timezone_utc-4") },
    { value: "Etc/GMT-5", label: t("generic.timezone_utc-5") },
    { value: "Etc/GMT-6", label: t("generic.timezone_utc-6") },
    { value: "Etc/GMT-7", label: t("generic.timezone_utc-7") },
    { value: "Etc/GMT-8", label: t("generic.timezone_utc-8") },
    { value: "Etc/GMT-9", label: t("generic.timezone_utc-9") },
    { value: "Etc/GMT-10", label: t("generic.timezone_utc-10") },
    { value: "Etc/GMT-11", label: t("generic.timezone_utc-11") },
  ];

  return (
  <>
      <select
        type="select"
        className="bg-[#DDE3CF] rounded-3xl text-sm sm:text-lg sm:leading-5 p-3 text-[#000000] w-full"
        label="select timezone"
        value={timeZone}
        onChange={(e) => setTimeZone(e.target.value)}
      >
          <option value="" className="text-gray-500">{t("register.select_timezone")}</option>
          {timezones.map((timezone) => (
            <option key={timezone.value} value={timezone.value}>
              {timezone.label}
            </option>
      ))}
      </select>
  </>
  );
};

export default TimezoneSelectNoGuess;