import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../components/Form/PaymentForm";
import Header from "../../components/header/Header";
import { MenuBar } from "../../components/Menu/Menu";
import Footer from "../../components/footer/Footer";
import { getPaymentKey } from "../../store/actions/payment.action";
import { useDispatch } from "react-redux";
import PaymentHistory from "./PaymentHistory";
import VisaIcon from "../../assets/image/Visa.svg";
import Mastercard from "../../assets/image/Mastercard.svg";
import AmericanEx from "../../assets/image/AmericanEx.svg";
import Alipay from "../../assets/image/Alipay.svg";
import WeChat from "../../assets/image/wechat-payment.svg";
import CashApp from "../../assets/image/Cashapp.svg";
import { stripe_publishable_key } from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(stripe_publishable_key);

export default function Payment() {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  let credit_unit = 15; // Every purchase must buy in a batch of 15 credits
  const [count, setCount] = useState(credit_unit);
  const [clientSecret, setClientSecret] = useState(null);
  const { t } = useTranslation();
  const finalAmount = Math.round(
    process.env.REACT_APP_CREDIT_COST * count * 100 * 1.05 + 30
  ); // $$ * 105% + $0.30

  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  useEffect(() => {
    if (!token) {
      navigation(`/login`);
    }
  }, [token, navigation]);

  const handleGetPaymentKey = async () => {
    if (token) {
      setClientSecret(null);
      setIsFetch(true);
      try {
        const clientSecret = await dispatch(
          getPaymentKey({ amount: finalAmount, token })
        );
        setClientSecret(clientSecret);
      } catch (error) {
        console.error("Error fetching payment key:", error);
      }
    }
  };

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const NumberSelector = ({ min, max, initialValue }) => {
    const decrementValue = () => {
      setCount((prevValue) => (prevValue > min ? prevValue - 15 : prevValue));
    };

    const handleChange = (e) => {
      const value = e.target.value;
      if (isNaN(value) && value !== "") {
        return;
      }
      if (value === "") {
        setCount(min);
      } else {
        const newValue = Math.min(Math.max(Number(value), min), max);
        setCount(newValue);
      }
    };

    const incrementValue = () => {
      setCount((prevValue) => (prevValue < max ? prevValue + 15 : prevValue));
    };

    return (
      <>
        <p className="lg:pl-[5rem] font-bold md:block hidden text-xl text-gray-800">
          {t("payment_page.title")}
        </p>
        <div className="md:block flex justify-between items-center md:w-auto w-full md:mb-0 mb-3">
          <div className="flex items-center">
            {!isFetch && count < max && (
              <button
                className="text-2xl md:text-5xl lg:text-6xl 2xl:text-7xl md:w-[50px] w-8 md:h-[100px] md:mb-4"
                onClick={decrementValue}
                disabled={count === min || isFetch}
              >
                {"-"}
              </button>
            )}
            <input
              type="text"
              className={`border-[1px] text-xl md:text-5xl lg:text-6xl 2xl:text-7xl border-[#004225] bg-[#F5F5DC] dm:w-[50px] w-8 sm:h-[50px] h-8 md:w-[150px] md:h-[70px] text-center ${
                isFetch ? "mx-20 mb-3" : ""
              }`}
              value={count}
              onChange={handleChange}
              onBlur={() => {
                if (count === "") setCount(min);
              }} // Ensure count is not left empty
              disabled={isFetch}
            />
            {!isFetch && count < max && (
              <button
                className="text-2xl md:text-5xl lg:text-6xl 2xl:text-7xl md:w-[50px] w-8 md:h-[100px] md:mb-4"
                onClick={incrementValue}
              >
                {"+"}
              </button>
            )}
          </div>
          <div className="md:hidden block text-right">
            <p className="md:mb-2 text-lg md:text-xl font-bold text-gray-800">
              {t("payment_page.total_amount")}
            </p>
            <p className="text-[#004225] text-3xl md:text-5xl lg:text-6xl 2xl:text-[90px] font-bold whitespace-nowrap">
              $ {finalAmount / 100}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-4xl 2xl:text-5xl font-bold md:text-center">
            {t("payment_page.title")}
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center lg:items-center mt-4 md:mt-0 md:p-12 lg:gap-32 md:gap-16 gap-4">
          <div className="flex flex-col md:w-2/3 w-full items-center md:items-start">
            {/* Number Selector */}
            <NumberSelector
              className="w-[60px] h-[60px] md:w-[100px] md:h-[100px] text-center text-2xl md:text-4xl border-2 border-red-600 rounded-md"
              min={1}
              max={1000}
              initialValue={1}
            />

            {/* Information Text */}
            <p className="text-center mt-4 sm:text-lg text-sm md:text-xl font-normal text-black">
              {t("payment_page.heading1")}{" "}
              <span className="font-bold">
                ${(process.env.REACT_APP_CREDIT_COST * credit_unit).toFixed(2)}
              </span>{" "}
              {t("payment_page.heading2")}
            </p>
          </div>
          {/* Total Amount Section */}
          <div className="md:w-1/3 md:block hidden w-full md:text-left text-right md:mb-0 mb-3">
            <p className="mb-2 text-lg md:text-xl font-bold text-gray-800">
              {t("payment_page.total_amount")}
            </p>
            <p className="text-[#004225] text-5xl md:text-5xl lg:text-6xl 2xl:text-[90px] font-bold whitespace-nowrap">
              $ {finalAmount / 100}
            </p>
          </div>
        </div>

        <div className="justify-center">
          <div className="text-[20px] font-semibold text-center flex flex-wrap md:justify-center items-center md:gap-3">
            <div className="lg:text-xl md:text-lg text-sm">
              {t("payment_page.text1")}
            </div>
            <div className="flex items-center gap-4 overflow-x-auto whitespace-nowrap">
              <img src={VisaIcon} alt="Visa" className="h-12" />
              <img src={Mastercard} alt="Mastercard" className="h-10" />
              <img src={AmericanEx} alt="American Express" className="h-10" />
              <img src={Alipay} alt="Alipay" className="h-8" />
              <img src={CashApp} alt="CashApp" className="h-8" />
              <div className="text-[#2dbd07] text-[8.7px] flex items-center">
                <img src={WeChat} alt="WeChat" className="h-[35px] mr-1" />
                微信支付
              </div>
            </div>
            {
              //<span>{t("payment_page.text2")}</span>
              //<img src={StripeImage} alt="Stripe" className="h-[60px] w-[100px]" />
            }
          </div>
        </div>
        <div className="justify-center">
          <div className="text-center justify-center items-center">
            {clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm clientSecret={clientSecret} amount={finalAmount} />
              </Elements>
            ) : (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded max-w-[500px] w-full"
                onClick={handleGetPaymentKey}
              >
                {t("payment_page.payment_button")}
              </button>
            )}
          </div>
        </div>
      </div>

      <div>
        <PaymentHistory />
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
