import React from 'react';
import { useTranslation } from 'react-i18next';

export const MakeWishTip = () => {
  const { t } = useTranslation(); // Destructure the `t` function from the hook

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        textAlign: 'center', // Center text alignment
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#495057',
        maxWidth: '80%', // Restrict width to make it responsive
        margin: '0 auto', // Center the div within the viewport
        flexWrap: 'wrap', // Allow wrapping for smaller viewports
      }}
    >
      <span>{t('leader_page.note_text')} </span>
      <a
        href={`${window.location.origin}/make-a-wish`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'underline',
          color: '#0000EE',
          marginLeft: '4px',
        }}
      >
        {t('leader_page.make_a_wish')}
      </a>
    </div>
  );
};
