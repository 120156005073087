import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import { getCurrentMeeting, cancel_with_note } from "../../store/actions/meeting.action";
import { Button } from "@material-tailwind/react";
import { transform_languages } from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";

export default function CancelWithNote() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [comment, setComment] = useState("");
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const currentMeeting = useSelector((state) => state.meeting.currentMeeting);
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getCurrentMeeting({ token, id }));
  }, [dispatch, token, mail, id]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const handleCancelButton = () => {
    dispatch(
      cancel_with_note({
        token,
        id,
        comment,
      })
    );
    navigation("/user/meetings");
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      <>
        <div className="flex flex-col md:flex-row">
          <div className="basis-1/2 flex flex-col items-center justify-center">
            <img
              src={
                currentMeeting.follower?.image ||
                "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
              }
              alt="UserImage"
              className="rounded-full boder-solid border-[10px] border-[#004225] w-[200px] h-[200px] md:w-[200px] md:h-[200px] xl:w-[300px] xl:h-[300px]"
            />
            <div className="text-3xl font-semibold mt-8">
              {currentMeeting.follower?.first_name +
                " " +
                currentMeeting.follower?.last_name}
            </div>
            <div className="text-xl font-normal mt-2">
              {t(transform_languages(currentMeeting.teaching_language))}
            </div>
            <div className="flex flex-col mt-4 items-center gap-4">
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.dt")}
                </div>
                <div className="text-2xl font-normal">
                  {" "}
                  {formatDateTime(currentMeeting?.meeting_happen_at)}
                </div>
              </div>
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.du")}
                </div>
                <div className="text-2xl font-normal">
                  {" "}
                  {currentMeeting.meeting_length} {t("generic.minute")}
                </div>
              </div>
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.lang")}
                </div>
                <div className="text-2xl font-normal">
                  {t(transform_languages(currentMeeting.teaching_language))}
                </div>
              </div>
            </div>
          </div>
          <div className="basis-1/2 flex flex-col items-center justify-center gap-8">
            <p className="text-center text-xl md:text-2xl xl:text-3xl">
              {t("cancel_with_note.write_cancel_note")}
            </p>
            <textarea
              label="Message"
              className="w-[280px] h-[200px] bg-[#F5F5DC] border-[2px] border-black p-2"
              value={comment}
              max={5}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              color="red"
              onClick={() => {
                handleCancelButton();
              }}
            >
              {t("cancel_with_note.cancel_button")}
            </Button>
          </div>
        </div>
      </>
      {/* )} */}
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
