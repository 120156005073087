import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../../components/Transformation/Transformations";
import moment from "moment-timezone";


export const SET_CREATE_MEETING = "SET_CREATE_MEETING";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_ALL_MEETING = "GET_ALL_MEETING";
export const GET_CURRENT_MEETING = "GET_CURRENT_MEETING";
export const GET_FILTER_MEETING = "GET_FILTER_MEETING";
export const SET_MEETING_REQUEST = "SET_MEETING_REQUEST";
export const SET_CANCEL_MEETING = "SET_CANCEL_MEETING";
export const SET_REJECT_MEETING = "SET_REJECT_MEETING";
export const GET_PENDING_MEETINGS_TEACHER = "GET_PENDING_MEETINGS_TEACHER";
export const GET_COMPLETED_MEETINGS_TEACHER = "GET_COMPLETED_MEETINGS_TEACHER";
export const GET_PENDING_MEETINGS_STUDENT = "GET_PENDING_MEETINGS_STUDENT";
export const GET_COMPLETED_MEETINGS_STUDENT = "GET_COMPLETED_MEETINGS_STUDENT";

export const filterMeeting = ({ searchLanguage, searchWeekday, token }) => (
  dispatch
) => {
  axios
    .post(
      base_url + "/meeting/filter/",
      { searchLanguage, searchWeekday },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: GET_FILTER_MEETING,
        payload: response.data,
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const getAllMeeting = ({ token, page }) => {
  return async (dispatch) => {
    // Return a new Promise
    try {
      const response = await axios.get(
        `${base_url}/meetings/?page=${page ? page : 1}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      dispatch({
        type: GET_ALL_MEETING,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
      throw error; // Rethrow error to be caught in async function
    }
  };
};

export const getPendingMeetingAsTeacher = ({ token, page }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/meetings/?is_teacher=${true}&is_completed=${false}&page=${page ? page : 1}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      dispatch({
        type: GET_PENDING_MEETINGS_TEACHER,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
      throw error;
    }
  };
};

export const getCompletedMeetingAsTeacher = ({ token, page }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/meetings/?is_teacher=${true}&is_completed=${true}&page=${page ? page : 1}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      dispatch({
        type: GET_COMPLETED_MEETINGS_TEACHER,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
      throw error;
    }
  };
};

export const getPendingMeetingAsStudent = ({ token, page }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/meetings/?is_teacher=${false}&is_completed=${false}&page=${page ? page : 1}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      dispatch({
        type: GET_PENDING_MEETINGS_STUDENT,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
      throw error;
    }
  };
};
export const getCompletedMeetingAsStudent = ({ token, page }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/meetings/?is_teacher=${false}&is_completed=${true}&page=${page ? page : 1}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      dispatch({
        type: GET_COMPLETED_MEETINGS_STUDENT,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
      throw error;
    }
  };
};

export const getCurrentMeeting = ({ token, id }) => (dispatch) => {
  axios
    .get(`${base_url}/meetings/${id}/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      return dispatch({
        type: GET_CURRENT_MEETING,
        payload: response.data,
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const searchMeeting = ({ text, token }) => (dispatch) => {
  axios
    .post(
      `${base_url}/meeting/search/`,
      { text },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      // return dispatch({
      //   type: ,
      //   payload: response.data,
      // });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const createMeeting = (meetingDetails, navigation) => (dispatch) => {
  const {
    id,
    token,
    leader_profile,
    follower,
    teaching_language,
    base_language,
    meeting_happen_at,
    meeting_length,
  } = meetingDetails;

  const date = meeting_happen_at.date;
  // Format the results
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const formatDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  const userTime = moment.tz(formatDate, meeting_happen_at.timezone);
  const utcTime = userTime.clone().utc();

  const requestData = {
    id,
    leader_profile,
    follower,
    teaching_language,
    base_language,
    meeting_happen_at: utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    meeting_length,
  };

  return axios.post(`${base_url}/meetings/`, requestData, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
        // console.log('hui response', response.status);
        toast.success("You have created a meeting successfully!", {
          position: "bottom-right",
        });

        dispatch({
          type: SET_CREATE_MEETING,
          payload: response.data,
        });
    })
    .catch((error) => {
        if (error.response && error.response.status === 409) {
            toast.error("Cannot schedule a Lingo course because the Lingo teacher is occupied at this time already. Please try another time or another Lingo teacher.", {
            position: "bottom-right",
            });
            navigation("/schedule-meeting");
        } else {
          toast.error("An unexpected error occurred. Please try it later.", {
            position: "bottom-right",
          });
        }
    });
}

export const accept = ({
  id,
  token,
  status
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${id}/`,
      {
        status
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have accepted meeting in successfully !", {
        position: "bottom-right",
      });
      dispatch(getCompletedMeetingAsTeacher({ token }));
      dispatch(getPendingMeetingAsTeacher({ token }));
      dispatch(getCompletedMeetingAsStudent({ token }));
      dispatch(getPendingMeetingAsStudent({ token }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};


export const complete = ({
  id,
  token,
  status
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${id}/`,
      {
        status
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      dispatch(getCompletedMeetingAsTeacher({ token }));
      dispatch(getPendingMeetingAsTeacher({ token }));
      dispatch(getCompletedMeetingAsStudent({ token }));
      dispatch(getPendingMeetingAsStudent({ token }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const review = ({
  token,
  comment,
  stars,
  meeting_id,
  status,
}) => (dispatch) => {
  axios
    .post(
      `${base_url}/reviews/`,
      { comment, stars, meeting_id, status },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have sent a review in successfully !", {
        position: "bottom-right",
      });
      // dispatch(complete({ id: meeting_id, token, status: "COMPLETED" }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};


export const review_no_show = ({
  token,
  meeting_id,
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${meeting_id}/`,
      {
        status: "TEACHER_NO_SHOW",
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have sent a review in successfully !", {
        position: "bottom-right",
      });
      // dispatch(complete({ id: meeting_id, token, status: "COMPLETED" }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const getReviews = ({ token, lid }) => (dispatch) => {
  axios
    .get(`${base_url}/reviews/?given_to=${lid}`)
    .then((response) => {
      return dispatch({ type: GET_REVIEWS, payload: response.data });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const reject = ({
  id,
  token,
  status
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${id}/`,
      {
        status
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have rejected meeting in successfully !", {
        position: "bottom-right",
      });
      dispatch(getAllMeeting({ token }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};


export const reject_with_note = ({
  token,
  id,
  comment
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${id}/`,
      {
        status: "REJECTED",
        comment: comment
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have rejected meeting in successfully !", {
        position: "bottom-right",
      });
      dispatch(getAllMeeting({ token }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};


export const cancel_with_note = ({
  token,
  id,
  comment
}) => (dispatch) => {
  axios
    .patch(
      `${base_url}/meetings/${id}/`,
      {
        status: "CANCELLED",
        comment: comment
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("The course is cancelled successfully!", {
        position: "bottom-right",
      });
      dispatch(getAllMeeting({ token }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};
