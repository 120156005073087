import { Button, Card, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddLeaderProfile } from "../Dialog/AddLeaderProfile";
import { transform_skillLevel } from "../../components/Transformation/Transformations";

export default function ProfileTable(props) {
  const { t } = useTranslation();
  const [teachingLanguage, setTeachingLanguage] = useState("");
  const [teachingLanguageSkill, setTeachingLanguageSkill] = useState("");
  const [changeIntroduction, setChangeIntroduction] = useState("");
  const [interestedTopic, setInterestedTopic] = useState("");
  const [creditAdjustmentRate, setCreditAdjustmentRate] = useState("");
  const [isChange, setIsChange] = useState("");
  const [addLeader, setAddLeader] = useState(false);

  const TABLE_HEAD = [
    "No",
    t("become_a_leader.teaching_lang"),
    t("become_a_leader.teaching_lang_level"),
    t("become_a_leader.course_intro"),
    t("become_a_leader.topics"),
    t("become_a_leader.rate"),
    ""
  ];

  return (
    <>
      <Card className="h-[250px] w-full overflow-scroll bg-[#F5F5DC]">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 p-4 bg-[#F5F5DC]"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.TABLE_ROWS &&
              props.TABLE_ROWS?.results?.map(
                (
                  {
                    introduction,
                    teaching_language,
                    teaching_language_skill_level,
                    interested_topics,
                    credit_adjustment_rate,
                    is_active,
                    id
                  },
                  index
                ) => {
                  const isLast = index === props.TABLE_ROWS.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
                  return (
                    <tr key={id}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {teaching_language === "EN" && "English"}
                          {teaching_language === "CN" && "Chinese"}
                          {teaching_language === "ES" && "Spanish"}
                        </Typography>
                        {/* )} */}
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {t(transform_skillLevel(teaching_language_skill_level))}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {introduction}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {interested_topics}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {credit_adjustment_rate + " Lingo Credits"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex justify-center">
                          <Button
                            color="amber"
                            className="mr-4"
                            onClick={() => {
                              setAddLeader(true);
                              setIsChange(id);
                              setTeachingLanguage(teaching_language);
                              setTeachingLanguageSkill(
                                teaching_language_skill_level
                              );
                              setChangeIntroduction(introduction);
                              setInterestedTopic(interested_topics);
                              setCreditAdjustmentRate(credit_adjustment_rate);
                            }}
                          >
                            {t("generic.edit")}
                          </Button>
                          {is_active ? (
                            <Button color="red" onClick={() => props.deactivate(id)}>
                              {t("generic.deactivate")}
                            </Button>
                          ) : (
                            <Button color="green" onClick={() => props.activate(id)}>
                              {t("generic.activate")}
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </Card>
      <AddLeaderProfile
        open={addLeader}
        close={() => setAddLeader(false)}
        teaching_language={teachingLanguage}
        teaching_language_skill_level={teachingLanguageSkill}
        introduction={changeIntroduction}
        interested_topics={interestedTopic}
        creditAdjustmentRate={creditAdjustmentRate}
        index={isChange}
      />
    </>
  );
}
