import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelectNoGuess from "../../components/Timezone/TimezonesNoGuess";

import {
  addAvailableTime,
  deleteAvailableTime,
  deleteImage,
  deactivateLeaderProfile,
  activateLeaderProfile,
  getAllLeader,
  getUserData,
  partialProfileUpdate,
  updateLeaderProfile,
  uploadImage,
} from "../../store/actions/user.action";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import ProfileTable from "../../components/Table/ProfileTable";
import { AddLeaderProfile } from "../../components/Dialog/AddLeaderProfile";
import upArrow from "../../assets/image/upArrow.svg";
import ChangeImage from "../../assets/image/change.svg";
import WeekDayButton from "../../components/Button/WeekDayButton";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
// import TimeTable from "../../components/Table/TimeTable";
import {
  transform_gender,
  transform_skillLevel,
  transform_timezone,
  transform_languages,
  transform_meeting_provider,
  // transform_timeslot
} from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import QRCodeGenerator from "../../utils/qrcode";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import VisaIcon from "../../assets/image/Visa.svg";
import Mastercard from "../../assets/image/Mastercard.svg";
import AmericanEx from "../../assets/image/AmericanEx.svg";
import Alipay from "../../assets/image/Alipay.svg";
import WeChat from "../../assets/image/wechat-payment.svg";
import CashApp from "../../assets/image/Cashapp.svg";

export default function UserProfile({ inputTab }) {
  const { t } = useTranslation();

  // const [birthday, setBirthday] = useState("");
  const [birthYear, setBirthYear] = useState(
    new Date().getFullYear().toString()
  );
  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    // Only accept a 4-digit number as valid input
    if (inputYear.length <= 4 && !isNaN(inputYear)) {
      setBirthYear(inputYear);
    } else {
      setBirthYear(new Date().getFullYear().toString());
    }
  };
  // Generate an array of years for the dropdown
  const years = [];
  let currentYear = new Date().getFullYear();
  let minYear = 1950;
  for (let year = currentYear; year >= minYear; year--) {
    years.push(year);
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [myCredit, setMyCredit] = useState("");
  // const [preferredName, setPreferredName] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [addLeader, setAddLeader] = useState(false);
  //   const [addLearner, setAddLearner] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [firstLanguage, setFirstLanguage] = useState("");
  const [firstLanguageSkillLevel, setFirstLanguageSkillLevel] = useState("");
  const [secondLanguage, setSecondLanguage] = useState("");
  const [secondLanguageSkillLevel, setSecondLanguageSkillLevel] = useState("");
  const [sex, setSex] = useState("");
  const [school, setSchool] = useState("");
  const [cityCountry, setCityCountry] = useState("");
  const [totalTeachingMinutes, setTotalTeachingMinutes] = useState("");
  const [totalLearningMinutes, setTotalLearningMinutes] = useState("");
  const [
    availableTeachingMinutesForCertificate,
    setAvailableTeachingMinutesForCertificate,
  ] = useState("");
  const [availableMoneyToCashOut, setAvailableMoneyToCashOut] = useState("");
  const [meetingProvider, setMeetingProvider] = useState("");

  const [tempImage, setTempImage] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  // const [weekDay, setWeekDay] = useState("");
  // const [selectWeekDay, setSelectWeekDay] = useState("");
  const [timeZone, setTimeZone] = useState("");
  // const [time, setTime] = useState("");
  // const [hour, setHour] = useState(null);
  // const [minute, setMinute] = useState(null);
  const [isAvailableTimeChange, setIsAvailableTimeChange] = useState(false);
  const [currentDay, setCurrentDay] = useState(0); // eslint-disable-line no-unused-vars
  // const [selectTime, setSelectTime] = useState("");
  // const [availabilityData, setAvailabilityData] = useState({});
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  // const [selectedDayTime, setSelectedDayTime] = useState({ day: "", time: "" });
  const [currentTab, setCurrentTab] = useState(inputTab);
  const [loading, setLoading] = useState(true);
  const [savingImage, setSavingImage] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [invitationUrl, setInvitationUrl] = useState("");
  // const [selectedTime, setSelectedTime] = useState("");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [allBookedSlots, setAllBookedSlots] = useState({});
  const [initialBookedSlots, setInitialBookedSlots] = useState([]);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  useEffect(() => {
    if (!token) {
      navigation(`/login`);
    }
  }, [token, navigation]);

  const mail = localStorage.getItem("mail");
  const userProfile = useSelector((state) => state.user.currentUser);
  // const user_timezone = userProfile?.timezone;
  const leaderData = useSelector((state) => state.user.getLeaders);
  const parsedUserProfile = useMemo(() => {
    let parsedProfile;
    try {
      if (
        userProfile &&
        typeof userProfile === "string" &&
        userProfile.trim() !== ""
      ) {
        parsedProfile = JSON.parse(userProfile);
      } else {
        parsedProfile = userProfile;
      }
    } catch (error) {
      console.error("Error parsing userProfile JSON:", error);
      parsedProfile = {};
    }
    return parsedProfile;
  }, [userProfile]);

  useEffect(() => {
    if (token && mail) dispatch(getUserData({ token, mail }));
    dispatch(getAllLeader(token));
  }, [dispatch, token, mail]);

  const closeIsChange = () => {
    setIsChange(false);
    setTempImage(null);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setTempFile(file);
    if (file) {
      setTempImage(URL.createObjectURL(file));
    }
    event.target.value = null;
  };

  // const rowDelete = (index) => {
  //   const id = parsedUserProfile?.availabilities[index]?.id;
  //   dispatch(deleteAvailableTime({ id, token, mail }));
  // };

  const deactivateLeader = (index) => {
    dispatch(
      deactivateLeaderProfile({
        token,
        mail,
        index: index,
      })
    );
  };

  const activateLeader = (index) => {
    dispatch(
      activateLeaderProfile({
        token,
        mail,
        index: index,
      })
    );
  };

  const updateLeader = (
    teaching_language,
    teaching_language_skill_level,
    introduction,
    interested_topics,
    creditAdjustmentRate,
    index
  ) => {
    dispatch(
      updateLeaderProfile({
        token,
        mail,
        teaching_language,
        teaching_language_skill_level,
        introduction,
        interested_topics,
        creditAdjustmentRate,
        index: leaderData?.results[index]?.id,
      })
    );
  };

  useEffect(() => {
    if (isAvailableTimeChange === false) {
      setSelectedSlots([]);
    }
    if (isAvailableTimeChange) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => document.body.classList.remove("overflow-hidden");
  }, [isAvailableTimeChange]);

  useEffect(() => {
    if (parsedUserProfile) {
      const userData = parsedUserProfile;
      setFirstName(userData?.first_name || "");
      setLastName(userData?.last_name || "");
      setMyCredit(userData?.credits || "0");
      setTotalTeachingMinutes(userData?.total_teaching_minutes || "0");
      setTotalLearningMinutes(userData?.total_learning_minutes || "0");
      setAvailableTeachingMinutesForCertificate(
        userData?.available_teaching_minutes_for_certificate || "0"
      );
      setAvailableMoneyToCashOut(
        userData?.available_money_to_cash_out / 100 || "0"
      );
      setMeetingProvider(userData?.meeting_provider || "");

      setBirthYear(userData?.year_of_birth || "");
      setSchool(userData?.school || "");
      setCityCountry(userData?.city_country || "");
      setSex(userData?.sex || "");
      setTimeZone(userData?.timezone || "");
      setFirstLanguage(userData?.first_language || "");
      setFirstLanguageSkillLevel(userData?.first_language_skill_level || "");
      setSecondLanguage(userData?.second_language || "");
      setSecondLanguageSkillLevel(userData?.second_language_skill_level || "");
    }
  }, [parsedUserProfile]);

  const sendUserBasicData = () => {
    const profileData = {
      firstName: firstName,
      lastName: lastName,
      timezone: timeZone,
      meetingProvider: meetingProvider,
      mail,
    };
    const id = parsedUserProfile?.id;
    dispatch(partialProfileUpdate(profileData, id, token));
    setIsChange(false);
  };

  const sendData = () => {
    const prevSex = parsedUserProfile?.sex;
    const prevFirstLanguage = parsedUserProfile?.first_language;
    const prevFirstLanguageSkillLevel =
      parsedUserProfile?.first_language_skill_level;
    const prevSecondLanguageSkillLevel =
      parsedUserProfile?.second_language_skill_level;
    var profileData = {};
    if (birthYear) {
      profileData = {
        birthYear: birthYear,
        school: school,
        cityCountry: cityCountry,
        sex: sex || prevSex,
        firstLanguage: firstLanguage || prevFirstLanguage,
        firstLanguageSkillLevel:
          firstLanguageSkillLevel || prevFirstLanguageSkillLevel,
        secondLanguage: secondLanguage,
        second_language_skill_level:
          secondLanguageSkillLevel || prevSecondLanguageSkillLevel,
        mail,
      };
    } else {
      profileData = {
        school: school,
        cityCountry: cityCountry,
        sex: sex || prevSex,
        firstLanguage: firstLanguage || prevFirstLanguage,
        firstLanguageSkillLevel:
          firstLanguageSkillLevel || prevFirstLanguageSkillLevel,
        secondLanguage: secondLanguage,
        second_language_skill_level:
          secondLanguageSkillLevel || prevSecondLanguageSkillLevel,
        mail,
      };
    }
    const id = parsedUserProfile?.id;
    dispatch(partialProfileUpdate(profileData, id, token));
    setIsChange(false);
  };

  useEffect(() => {
    const userTimezone = parsedUserProfile?.timezone || moment.tz.guess();
    const availabilities = parsedUserProfile?.availabilities || [];

    const initialSlots = availabilities.map((slot) => {
      const localTime = moment()
        .tz(slot.timezone)
        .day(slot.day)
        .hour(slot.hour)
        .minute(slot.minute)
        .second(0)
        .tz(userTimezone);
      if (localTime.isBefore(moment())) {
        localTime.add(7, "days");
      }
      return {
        id: slot.id,
        day: localTime.format("ddd"),
        time: localTime.format("HH:mm"),
      };
    });

    setInitialBookedSlots(initialSlots);

    const slotsByDay = dayArray.reduce((acc, day) => {
      acc[day] = initialSlots
        .filter((slot) => slot.day === day)
        .map((slot) => slot.time);
      return acc;
    }, {});

    setAllBookedSlots(slotsByDay);

    setCurrentDay(new Date().getDay() || 7);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedUserProfile?.availabilities, parsedUserProfile?.timezone]);

  const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const hoursArray = Array.from(
    { length: 24 },
    (_, i) => `${i.toString().padStart(2, "0")}:00`
  );

  const toggleSlot = (day, time) => {
    const slot = { day, time };

    // Check if it's an already booked slot
    const isAlreadyBooked = initialBookedSlots.some(
      (s) => s.day === day && s.time === time
    );

    if (isAlreadyBooked) {
      const isMarkedForDeletion = selectedSlots.some(
        (s) => s.day === day && s.time === time
      );

      if (isMarkedForDeletion) {
        setSelectedSlots(
          selectedSlots.filter((s) => !(s.day === day && s.time === time))
        );
      } else {
        setSelectedSlots([...selectedSlots, slot]);
      }
    } else {
      const isSelected = selectedSlots.some(
        (s) => s.day === day && s.time === time
      );
      if (isSelected) {
        setSelectedSlots(
          selectedSlots.filter((s) => !(s.day === day && s.time === time))
        );
      } else {
        setSelectedSlots([...selectedSlots, slot]);
      }
    }
  };

  const saveTimeSlots = () => {
    const userTimezone = parsedUserProfile?.timezone || moment.tz.guess();

    const toAdd = selectedSlots.filter(
      (slot) =>
        !initialBookedSlots.some(
          (booked) => booked.day === slot.day && booked.time === slot.time
        )
    );

    const toDelete = initialBookedSlots.filter((booked) =>
      selectedSlots.some(
        (slot) => slot.day === booked.day && slot.time === booked.time
      )
    );

    if (toAdd.length === 0 && toDelete.length === 0) {
      toast.warn("No changes to save!", { position: "bottom-right" });
      return;
    }

    toAdd.forEach(({ day, time }) => {
      const [hour, minute] = time.split(":");
      dispatch(
        addAvailableTime({
          day,
          hour: parseInt(hour),
          minute: parseInt(minute),
          token,
          timezone: userTimezone,
          mail,
        })
      );
    });

    toDelete.forEach(({ id }) => {
      dispatch(deleteAvailableTime({ id, token, mail }));
    });

    setSelectedSlots([]);
    setIsAvailableTimeChange(false);
  };

  // const saveTimeSlots = () => {
  //   const initialBookedSlots =
  //     leaderData.results?.[0]?.owner?.availabilities.map((slot) => ({
  //       day: slot.day,
  //       time: `${slot.hour.toString().padStart(2, "0")}:${slot.minute
  //         .toString()
  //         .padStart(2, "0")}`
  //     }));

  //   const newSlots = selectedSlots.filter(
  //     (slot) =>
  //       !initialBookedSlots.some(
  //         (booked) => booked.day === slot.day && booked.time === slot.time
  //       )
  //   );
  //   console.log("New slots to be saved:", newSlots);

  //   if (newSlots.length === 0) {
  //     toast.warn("No slots selected to save!", {
  //       position: "bottom-right"
  //     });
  //     return;
  //   }

  //   newSlots.forEach(({ day, time }) => {
  //     const [hour, minute] = time.split(":");
  //     dispatch(
  //       addAvailableTime({
  //         day,
  //         hour: parseInt(hour, 10),
  //         minute: parseInt(minute, 10),
  //         token,
  //         mail
  //       })
  //     );
  //   });
  // };

  // const formattedAvailability = useMemo(() => {
  //   const availabilities = parsedUserProfile?.availabilities || [];
  //   const availability = {};
  //   availabilities.forEach((slot) => {
  //     let [zonedDay, formattedDate] = transform_timeslot(slot, user_timezone);
  //     if (!availability[zonedDay]) {
  //       availability[zonedDay] = [];
  //     }
  //     availability[zonedDay].push(formattedDate);
  //   });
  //   return availability;
  // }, [user_timezone, parsedUserProfile?.availabilities]);

  // useEffect(() => {
  //   if (formattedAvailability) {
  //     setAvailabilityData(formattedAvailability);
  //   }
  // }, [formattedAvailability]);

  const MAX_IMAGE_SIZE_KB = 500;
  const saveImage = async () => {
    if (!tempFile) {
      toast.warn("There is no image.", {
        position: "bottom-right",
      });
      return;
    }

    const imageSizeInMB = tempFile.size / 1024;

    if (imageSizeInMB > MAX_IMAGE_SIZE_KB) {
      alert(
        `Image size is too large. Please select an image smaller than ${MAX_IMAGE_SIZE_KB} KB.`
      );
      return;
    }
    setSavingImage(true);
    try {
      await dispatch(
        uploadImage({
          token,
          mail,
          image: tempFile,
          id: parsedUserProfile?.id,
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setTempImage(null);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setSavingImage(false);
    }
  };

  const deleteImg = () => {
    dispatch(
      deleteImage({
        token,
        mail,
        userId: parsedUserProfile?.id,
      })
    );
    setTempImage(null);
    setTempFile(null);
  };

  // const addTime = () => {
  //   if (weekDay === "" || hour === "" || minute === "") {
  //     toast.warn(t("generic.enter_all"), {
  //       position: "bottom-right"
  //     });
  //     return;
  //   }

  //   // if (note.length > 98) {
  //   //   toast.warn(t("generic.note_too_long"), {
  //   //     position: "bottom-right"
  //   //   });
  //   //   return;
  //   // }

  //   // backend will add user's current timezone to the record.
  //   // We assume that users always want a timeslot (for example, PST 8:00 PM) no matter it is daylight saving or not
  //   // For frontend to display the timeslot, it should be displayed based on both of the record's timezone and viewer's timezone
  //   dispatch(
  //     addAvailableTime({
  //       day: weekDay,
  //       // note,
  //       hour: hour,
  //       minute: minute,
  //       token,
  //       mail
  //     })
  //   );

  //   setWeekDay("");
  //   setTime("");
  //   setHour("");
  //   setMinute("");
  //   // setNote("");
  // };

  // const SaveTime = () => {
  //   setIsAvailableTimeChange(false);
  // };

  const data = [
    {
      label: t("user_home.profile"),
      value: "profile",
    },
    {
      label: t("user_home.leader_profile"),
      value: "leaderProfile",
    },
  ];

  const openIsChange = () => {
    setIsChange(true);
  };

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  // const handleTimeChange = (e) => {
  //   const value = e.target.value;
  //   setTime(value);

  //   if (value) {
  //     const [time, period] = value.split(" ");
  //     let [hour, minute] = time.split(":").map(Number);

  //     if (period === "pm" && hour !== 12) {
  //       hour += 12;
  //     } else if (period === "am" && hour === 12) {
  //       hour = 0;
  //     }

  //     setHour(hour);
  //     setMinute(minute);
  //   } else {
  //     setHour("");
  //     setMinute("");
  //   }
  // };

  // const TimeSlotsList = ({ day, data, selectedDayTime }) => {
  //   return (
  //     <div
  //       className={`slotCard border border-transparent bg-white border-1 rounded-sm p-2`}
  //     >
  //       <div
  //         className={`relative flex items-center justify-between gap-4 text-gray-700 shadow-none`}
  //       >
  //         <h5
  //           className={`text-xl font-semibold leading-snug tracking-normal text-gray-700 font-sans`}
  //         >
  //           {data}
  //         </h5>
  //       </div>
  //     </div>
  //   );
  // };
  const handleTabChange = (value) => {
    setCurrentTab(value);
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    setInvitationCode(parsedUserProfile?.invitation_code);
    setInvitationUrl("https://lingoclub.org/?ref=" + invitationCode);
  }, [leaderData, invitationCode, parsedUserProfile]);

  const [currentSection, setCurrentSection] = useState("section1");

  // Define sections with unique IDs and display names
  const sections = [
    { id: "section1", displayName: t("user_home.step_1") },
    { id: "section2", displayName: t("user_home.step_2") },
    { id: "section3", displayName: t("user_home.step_3") },
    { id: "section4", displayName: t("user_home.step_4") },
  ];

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      <Tabs value={currentTab}>
        <TabsHeader className="bg-[#004225]">
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => {
                handleTabChange(value);
              }}
            >
              <div className="flex items-center gap-2 md:text-xl sm:text-sm text-xs">
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="min-h-[500px]">
          <TabPanel
            className="sm:p-4 py-4 px-0"
            key={"profile"}
            value={"profile"}
          >
            <div className="flex md:flex-row flex-col-reverse gap-5">
              <div className="md:w-2/4 w-full pt-10 relative">
                <button className="absolute top-0 right-0">
                  <img
                    src={ChangeImage}
                    alt="changeProfile"
                    className="md:w-[40px] md:h-[40px]"
                    onClick={openIsChange}
                  />
                </button>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.first_name")}:
                  </p>
                  {isChange === false ? (
                    <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                      {parsedUserProfile?.first_name}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="w-full border-[1px] border-[#004225] bg-[#F5F5DC] rounded-md px-3 py-1"
                      value={firstName || ""}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  )}
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.last_name")}:
                  </p>
                  {isChange === false ? (
                    <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                      {parsedUserProfile?.last_name}
                    </p>
                  ) : (
                    <input
                      type="text"
                      className="w-full border-[1px] border-[#004225] bg-[#F5F5DC] rounded-md px-3 py-1"
                      value={lastName || ""}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  )}
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.email")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {mail}
                  </p>
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.timezone")}:
                  </p>
                  {isChange === false ? (
                    <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                      {t(transform_timezone(parsedUserProfile?.timezone))}
                    </p>
                  ) : (
                    <TimezoneSelectNoGuess
                      timeZone={timeZone}
                      setTimeZone={setTimeZone}
                    />
                    //                      <select
                    //                        type="select"
                    //                        className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-[300px] h-[40px]"
                    //                        value={timeZone}
                    //                        onChange={(e) => setTimeZone(e.target.value)}
                    //                      >
                    //                        <option value="">{t("user_home.select")}</option>
                    //                        <option value="US/Pacific">
                    //                          {t("generic.timezone_pacific")}
                    //                        </option>
                    //                        <option value="US/Eastern">
                    //                          {t("generic.timezone_eastern")}
                    //                        </option>
                    //                        <option value="US/Mountain">
                    //                          {t("generic.timezone_mountain")}
                    //                        </option>
                    //                        <option value="US/Central">
                    //                          {t("generic.timezone_central")}
                    //                        </option>
                    //                        <option value="PRC">{t("generic.timezone_prc")}</option>
                    //                      </select>
                  )}
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.total_teaching")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {totalTeachingMinutes}
                  </p>
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.total_learning")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {totalLearningMinutes}
                  </p>
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225]">
                    {t("user_home.total_certificate")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {availableTeachingMinutesForCertificate}
                  </p>
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] whitespace-nowrap">
                    {t("user_home.available_cash_out")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {availableMoneyToCashOut}
                  </p>
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 text-[#004225] sm:whitespace-nowrap">
                    {t("user_home.meeting_provider")}:
                  </p>
                  {isChange === false ? (
                    <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                      {t(transform_meeting_provider(meetingProvider))}
                    </p>
                  ) : (
                    <select
                      type="select"
                      className="border-[1px] text-sm sm:text-lg sm:leading-5 border-[#004225] bg-[#F5F5DC] w-full rounded-md px-3 py-1"
                      value={meetingProvider}
                      onChange={(e) => setMeetingProvider(e.target.value)}
                    >
                      <option value="">{t("user_home.select")}</option>
                      <option value="ZOOM">{t("user_home.zoom")}</option>
                      <option value="VooV">{t("user_home.voov")}</option>
                    </select>
                  )}
                </div>
                <div className="mb-4 flex items-center gap-2">
                  <p className="text-sm sm:text-lg md:text-xl font-medium text-[#004225]">
                    {t("user_home.my_credit")}:
                  </p>
                  <p className="text-sm sm:text-lg sm:leading-5 text-black whitespace-nowrap">
                    {myCredit}
                  </p>
                </div>
                <div className="flex  flex-col justify-center items-center  md:gap-4 gap-2 md:hidden block">
                  <a
                    href="/user/payment"
                    className="text-white bg-[#004225] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg sm:text-sm text-xs px-12 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "
                  >
                    {t("user_home.buy_credits")}
                  </a>
                  <a
                    href="/user/meetings"
                    className="text-white bg-[#004225] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg sm:text-sm text-xs px-12 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "
                  >
                    {t("user_home.all_courses")}
                  </a>
                </div>
              </div>
              <div className="w-2/4 md:m-0 ml-auto md:block hidden">
                <div className="flex flex-col bg-[#0042254f] items-center justify-center p-4 border-4 max-w-96 m-auto border-slate-500 rounded-xl">
                  <img src={upArrow} alt="upArrow" className="size-12 mb-2" />
                  <p className="text-center text-sm md:text-xl text-[#0d3624]">
                    {t("user_home.become_leader")}
                  </p>
                </div>
                <div className="flex flex-col justify-center items-center  md:gap-4 gap-2 mt-4">
                  <a
                    href="/user/payment"
                    className="text-white bg-[#004225] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {t("user_home.buy_credits")}
                  </a>
                  <a
                    href="/user/meetings"
                    className="text-white bg-[#004225] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {t("user_home.all_courses")}
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col gap-4 p-2 pb-12">
              <div className="flex justify-end">
                <div></div>
                <div className="basis-1/2 flex flex-col gap-4"></div>
              </div>

              <div className="flex flex-col xl:flex-row items-center gap-8">
                <div className="basis-1/2 flex flex-col items-center">
                  <div className="mt-[-35px]"></div>
                </div>
              </div>
              <div className="text-[20px] font-semibold text-center flex flex-wrap md:justify-center items-center gap-4 whitespace-nowrap overflow-x-auto">
                <div className="lg:text-xl md:text-lg text-sm">
                  {t("payment_page.text1")}
                </div>
                <div className="flex items-center md:gap-4 gap-2 flex-wrap">
                  <img src={VisaIcon} alt="Visa" className="h-12" />
                  <img src={Mastercard} alt="Mastercard" className="h-10" />
                  <img
                    src={AmericanEx}
                    alt="American Express"
                    className="h-10"
                  />
                  <img src={Alipay} alt="Alipay" className="h-8" />
                  <img src={CashApp} alt="CashApp" className="h-8" />
                  <div className="text-[#2dbd07] text-[8.7px] flex items-center">
                    <img src={WeChat} alt="WeChat" className="h-[35px] mr-1" />
                    微信支付
                  </div>
                </div>
              </div>
              {isChange && (
                <div className="flex justify-center gap-4">
                  <button
                    className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                    onClick={closeIsChange}
                  >
                    {t("generic.cancel")}
                  </button>
                  <button
                    className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                    onClick={sendUserBasicData}
                  >
                    {t("generic.save")}
                  </button>
                </div>
              )}
            </div>

            <div className="flex justify-center"></div>

            <div>
              <p className="text-lg font-medium leading-relaxed text-gray-800 mt-4 font p-2 bg-[#0042254f] border-l-4 border-green-900 rounded-lg shadow-sm text-center">
                <li>{t("user_home.refer_other_1")}</li>
                <li>{t("user_home.refer_other_2")}</li>
                {t("user_home.refer_other_3")} {"\u00A0\u00A0\u00A0"}{" "}
                {invitationUrl}
              </p>
            </div>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <h1>QR Code for the URL: {invitationUrl}</h1>
              <QRCodeGenerator url={invitationUrl} width={300} />
            </div>
          </TabPanel>
          <TabPanel key={"leaderProfile"} value={"leaderProfile"}>
            <Breadcrumbs
              sections={sections}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
            <div className="flex flex-col gap-10 mt-2">
              <div
                id="section1"
                className="w-full flex flex-col  p-2 md:pb-12 pb-2"
              >
                <div className="relative w-full text-center">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                    {t("user_home.public_info")}
                  </span>
                </div>

                <button className="flex justify-end">
                  <img
                    src={ChangeImage}
                    alt="changeProfile"
                    className="md:w-[40px] md:h-[40px]"
                    onClick={openIsChange}
                  />
                </button>
                <div className="flex gap-5 md:gap-32 md:items-center flex-col md:flex-row ">
                  <div className="w-1/2 flex flex-col items-center md:gap-3 gap-5">
                    <div className="flex w-full items-center gap-2">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap text-[#004225] md:text-[#004225]  md:w-[85%]">
                        {t("user_home.1st_lang")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {t(
                            transform_languages(
                              parsedUserProfile?.first_language
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] "
                          value={firstLanguage || ""}
                          onChange={(e) => setFirstLanguage(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="EN">{t("generic.english")}</option>
                          <option value="ES">{t("generic.spanish")}</option>
                          <option value="CN">{t("generic.chinese")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex gap-2.5 items-center w-full ">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap text-[#004225] md:text-[#004225] md:w-[85%]">
                        {t("user_home.1st_lang_skill")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {t(
                            transform_skillLevel(
                              parsedUserProfile?.first_language_skill_level
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC]"
                          value={firstLanguageSkillLevel || ""}
                          onChange={(e) =>
                            setFirstLanguageSkillLevel(e.target.value)
                          }
                        >
                          <option value={""}>{t("user_home.select")}</option>
                          <option value={"1"}>{t("generic.skill_1")}</option>
                          <option value={"2"}>{t("generic.skill_2")}</option>
                          <option value={"3"}>{t("generic.skill_3")}</option>
                          <option value={"4"}>{t("generic.skill_4")}</option>
                          <option value={"5"}>{t("generic.skill_5")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex gap-2.5 items-center w-full ">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap  text-[#004225] md:text-[#004225] md:w-[85%]">
                        {t("user_home.2nd_lang")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {t(
                            transform_languages(
                              parsedUserProfile?.second_language
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC]"
                          value={secondLanguage || ""}
                          onChange={(e) => setSecondLanguage(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="EN">{t("generic.english")}</option>
                          <option value="ES">{t("generic.spanish")}</option>
                          <option value="CN">{t("generic.chinese")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex w-full items-center gap-2 ">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap  text-[#004225] md:text-[#004225] md:w-[85%]">
                        {t("user_home.2nd_lang_skill")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {t(
                            transform_skillLevel(
                              parsedUserProfile?.second_language_skill_level
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] "
                          value={secondLanguageSkillLevel || ""}
                          onChange={(e) =>
                            setSecondLanguageSkillLevel(e.target.value)
                          }
                        >
                          <option value={""}>{t("user_home.select")}</option>
                          <option value={"1"}>{t("generic.skill_1")}</option>
                          <option value={"2"}>{t("generic.skill_2")}</option>
                          <option value={"3"}>{t("generic.skill_3")}</option>
                          <option value={"4"}>{t("generic.skill_4")}</option>
                          <option value={"5"}>{t("generic.skill_5")}</option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col gap-5 md:gap-4 items-center">
                    <div className="flex w-full items-center gap-2">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap  text-[#004225] md:text-[#004225] md:w-2/3">
                        {isChange
                          ? t("user_home.birthday")
                          : t("user_home.age")}
                      </p>
                      {isChange ? (
                        // Do NOT delete. This is a good example about DataPicker
                        //                        <DatePicker
                        //                          style={{ width: "100px" }}
                        //                          selected={birthday}
                        //                          onChange={(date) => {
                        //                            setBirthday(date);
                        //                          }}
                        //                          className="rounded-none px-2.5 bg-[#F5F5DC] border border-[#004225] text-gray-900 text-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[94%] p-0 ml-3"
                        //                          placeholderText={t("user_home.select_date")}
                        //                        />
                        //                            <div>
                        //                              <label htmlFor="birthYear" className="block text-lg font-medium text-gray-700">
                        //                                Year of Birth:
                        //                              </label>
                        //                              <input
                        //                                id="birthYear"
                        //                                type="number"
                        //                                value={birthYear}
                        //                                onChange={handleYearChange}
                        //                                placeholder="Enter year of birth"
                        //                                className="border border-[#004225] p-2 rounded-none bg-[#F5F5DC] text-gray-900 w-[100px] ml-3"
                        //                              />
                        //                            </div>
                        <div>
                          <label
                            htmlFor="birthYear"
                            className="block text-lg font-medium text-gray-700"
                          >
                            Year of Birth:
                          </label>
                          <select
                            id="birthYear"
                            value={birthYear}
                            onChange={handleYearChange}
                            className="border border-[#004225] p-2 rounded-none bg-[#F5F5DC] text-gray-900 w-[150px] ml-3 "
                          >
                            <option value="" disabled>
                              Select a year
                            </option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {parsedUserProfile?.age}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center w-full gap-2">
                      <p className="text-sm sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap  text-[#004225] md:text-[#004225] md:w-2/3">
                        {t("user_home.school")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black">
                          {parsedUserProfile?.school}
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="border-[1px] border-[#004225] pl-2 bg-[#F5F5DC] text-lg "
                          value={school || ""}
                          onChange={(e) => setSchool(e.target.value)}
                        />
                      )}
                    </div>
                    <div className="flex items-center w-full gap-2">
                      <p className="text-sm  sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap text-[#004225] md:text-[#004225] md:w-2/3">
                        {t("user_home.city_country")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black ">
                          {parsedUserProfile?.city_country}
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="border-[1px] border-[#004225] pl-2 bg-[#F5F5DC] text-lg "
                          value={cityCountry || ""}
                          onChange={(e) => setCityCountry(e.target.value)}
                        />
                      )}
                    </div>
                    <div className="flex items-center w-full gap-2">
                      <p className="text-sm  sm:text-lg sm:leading-5 leading-3 font-medium mb-1 md:text-2xl md:font-medium whitespace-nowrap text-[#004225] md:text-[#004225] md:w-2/3">
                        {t("user_home.sex")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light text-black md:text-black ">
                          {t(transform_gender(parsedUserProfile?.sex))}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] "
                          value={sex}
                          onChange={(e) => setSex(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="M">{t("generic.male")}</option>
                          <option value="F">{t("generic.female")}</option>
                          <option value="O">{t("generic.other_gender")}</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                {isChange && (
                  <div className="flex justify-end gap-4">
                    <button
                      className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                      onClick={closeIsChange}
                    >
                      {t("generic.cancel")}
                    </button>
                    <button
                      className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                      onClick={sendData}
                    >
                      {t("generic.save")}
                    </button>
                  </div>
                )}
              </div>
              <div id="section2" className="relative w-full text-center">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                  {t("user_home.my_course")}
                </span>
              </div>
              <div className="flex gap-5 justify-between">
                <p className="w-[calc(100% - 100px)]">
                  {t("user_home.add_new_course")}
                </p>
                <button className="flex justify-end w-[30%]">
                  <img
                    src={ChangeImage}
                    alt="addCourse"
                    className="md:w-[40px] md:h-[40px]"
                    onClick={() => setAddLeader(true)}
                  />
                </button>
              </div>
              <div className="flex flex-col gap-10">
                <ProfileTable
                  TABLE_ROWS={leaderData}
                  update={(
                    trainingLanguage,
                    teaching_language_skill_level,
                    changeIntroduction,
                    interested_topics,
                    creditAdjustmentRate,
                    index
                  ) =>
                    updateLeader(
                      trainingLanguage,
                      teaching_language_skill_level,
                      changeIntroduction,
                      interested_topics,
                      creditAdjustmentRate,
                      index
                    )
                  }
                  deactivate={(index) => deactivateLeader(index)}
                  activate={(index) => activateLeader(index)}
                />
              </div>
            </div>
            <div id="section3" className="relative w-full text-center mt-10">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                {t("user_home.my_avail")}
              </span>
            </div>
            <div className="flex justify-end">
              <button>
                <img
                  src={ChangeImage}
                  alt="addTimeslot"
                  className="md:w-[40px] md:h-[40px]"
                  onClick={(e) => setIsAvailableTimeChange(true)}
                />
              </button>
            </div>

            {
              !isAvailableTimeChange ? (
                <>
                  <div className="flex justify-center">
                    {t("user_home.add_time_slot")}
                  </div>
                  <div className="flex justify-center">
                    {t("meeting.table_text")}{" "}
                    <span style={{ color: "red" }}>
                      {" "}
                      {t(transform_timezone(parsedUserProfile?.timezone))}{" "}
                    </span>
                  </div>
                  <div className="overflow-scroll md:overflow-auto">
                    <div className="grid grid-cols-7 p-4 py-[47px] xl:px-[220px] lg:px-28 w-[800px] md:w-full">
                      {dayArray.map((day) => (
                        <div key={day} className="flex flex-col items-center">
                          <WeekDayButton name={day} />
                          <div className="space-y-2 mt-3">
                            {allBookedSlots[day]?.length > 0 ? (
                              allBookedSlots[day].map((time, index) => (
                                <div
                                  key={index}
                                  className="text-center px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded font-semibold"
                                >
                                  {time}
                                </div>
                              ))
                            ) : (
                              <div className="text-gray-700 text-md font-semibold">
                                No slots
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : null
              // <div className="flex flex-col gap-8">
              //   <p className="text-xl md:text-2xl">
              //     {t("user_home.add_day_avail_1")}{" "}
              //     <span style={{ color: "red" }}>
              //       {" "}
              //       {t(transform_timezone(parsedUserProfile?.timezone))}{" "}
              //     </span>{" "}
              //     {t("user_home.add_day_avail_2")}
              //   </p>
              //   <div className="overflow-x-auto pt-4">
              //     <div className="flex space-x-4 justify-center">
              //       {dayArray.map((day, index) => (
              //         <div key={day} className="flex flex-col items-center">
              //           <WeekDayButton
              //             name={day}
              //             nowState=""
              //             // isEnabled={currentDay >= index + 1}
              //           />
              //           <div className="flex flex-col space-y-1 pt-2">
              //             {hoursArray.map((hour) => {
              //               return (
              //                 <button
              //                   key={`${day}-${hour}`}
              //                   onClick={() => toggleSlot(day, hour)}
              //                   className={`text-center py-1 px-2 text-gray-700 text-md border font-semibold border-gray-300 ${
              //                     selectedSlots.some((s) => {
              //                       return s.day === day && s.time === hour;
              //                     })
              //                       ? "bg-green-900 text-white"
              //                       : "bg-gray-50"
              //                   }`}
              //                 >
              //                   {hour}
              //                 </button>
              //               );
              //             })}
              //           </div>
              //         </div>
              //       ))}
              //     </div>
              //     <div className="flex justify-center mt-5">
              //       <button
              //         className="flex items-center hover:bg-[#005d34] bg-[#004225] rounded-[5px] px-[100px] py-[8px] text-white"
              //         onClick={saveTimeSlots}
              //       >
              //         {t("generic.add")}
              //       </button>
              //     </div>
              //   </div>
              //   {/* <div className="flex flex-col"> */}
              //   {/* <div className="flex flex-col md:flex-row items-center justify-center gap-12">
              //       <div className="flex items-center">
              //         <p>{t("user_home.day")}</p>
              //         <select
              //           type={t("user_home.select")}
              //           value={weekDay}
              //           className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] w-[120px] h-[50px] text-2xl"
              //           onChange={(e) => setWeekDay(e.target.value)}
              //         >
              //           <option value="">{t("user_home.select")}</option>
              //           <option value="Mon">{t("generic.mon")}</option>
              //           <option value="Tue">{t("generic.tue")}</option>
              //           <option value="Wed">{t("generic.wed")}</option>
              //           <option value="Thu">{t("generic.thu")}</option>
              //           <option value="Fri">{t("generic.fri")}</option>
              //           <option value="Sat">{t("generic.sat")}</option>
              //           <option value="Sun">{t("generic.sun")}</option>
              //         </select>
              //       </div>
              //       <div className="flex items-center">
              //         <p>{t("user_home.time_slot")}</p>
              //         <select
              //           type={t("user_home.select")}
              //           value={time}
              //           className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] w-[150px] h-[50px] text-2xl"
              //           onChange={handleTimeChange}
              //         >
              //           <option value="">{t("user_home.select")}</option>
              //           <option value="00:00 am">00:00 am</option>
              //           <option value="00:30 am">00:30 am</option>
              //           <option value="01:00 am">01:00 am</option>
              //           <option value="01:30 am">01:30 am</option>
              //           <option value="02:00 am">02:00 am</option>
              //           <option value="02:30 am">02:30 am</option>
              //           <option value="03:00 am">03:00 am</option>
              //           <option value="03:30 am">03:30 am</option>
              //           <option value="04:00 am">04:00 am</option>
              //           <option value="04:30 am">04:30 am</option>
              //           <option value="05:00 am">05:00 am</option>
              //           <option value="05:30 am">05:30 am</option>
              //           <option value="06:00 am">06:00 am</option>
              //           <option value="06:30 am">06:30 am</option>
              //           <option value="07:00 am">07:00 am</option>
              //           <option value="07:30 am">07:30 am</option>
              //           <option value="08:00 am">08:00 am</option>
              //           <option value="08:30 am">08:30 am</option>
              //           <option value="09:00 am">09:00 am</option>
              //           <option value="09:30 am">09:30 am</option>
              //           <option value="10:00 am">10:00 am</option>
              //           <option value="10:30 am">10:30 am</option>
              //           <option value="11:00 am">11:00 am</option>
              //           <option value="11:30 am">11:30 am</option>
              //           <option value="12:00 am">12:00 am</option>
              //           <option value="12:30 am">12:30 am</option>
              //           <option value="01:00 pm">01:00 pm</option>
              //           <option value="01:30 pm">01:30 pm</option>
              //           <option value="02:00 pm">02:00 pm</option>
              //           <option value="02:30 pm">02:30 pm</option>
              //           <option value="03:00 pm">03:00 pm</option>
              //           <option value="03:30 pm">03:30 pm</option>
              //           <option value="04:00 pm">04:00 pm</option>
              //           <option value="04:30 pm">04:30 pm</option>
              //           <option value="05:00 pm">05:00 pm</option>
              //           <option value="05:30 pm">05:30 pm</option>
              //           <option value="06:00 pm">06:00 pm</option>
              //           <option value="06:30 pm">06:30 pm</option>
              //           <option value="07:00 pm">07:00 pm</option>
              //           <option value="07:30 pm">07:30 pm</option>
              //           <option value="08:00 pm">08:00 pm</option>
              //           <option value="08:30 pm">08:30 pm</option>
              //           <option value="09:00 pm">09:00 pm</option>
              //           <option value="09:30 pm">09:30 pm</option>
              //           <option value="10:00 pm">10:00 pm</option>
              //           <option value="10:30 pm">10:30 pm</option>
              //           <option value="11:00 pm">11:00 pm</option>
              //           <option value="12:30 pm">11:30 pm</option>
              //         </select>
              //       </div>
              //       <input
              //         type="Note"
              //         placeholder={t("user_home.note_text")}
              //         value={note}
              //         onChange={(e) => setNote(e.target.value)}
              //         className="border-[1px] placeholder-darkGrey-500 border-[#004225] ml-2 h-[50px] bg-[#F5F5DC] rounded-[1px] text-1xl p-2 w-[200px] md:w-[300px] lg:w-[300px]"
              //       />
              //       <button
              //         className="bg-[#004225] rounded-[5px] w-[50px] h-[30px] text-white"
              //         onClick={() => {
              //           addTime();
              //         }}
              //       >
              //         {t("generic.add")}
              //       </button>
              //     </div>

              //     <TimeTable
              //       TABLE_ROWS={parsedUserProfile?.availabilities}
              //       delete={(index) => rowDelete(index)}
              //     /> */}
              //   {/* </div> */}
              //   <div className="flex justify-end gap-4">
              //     <button
              //       className="bg-[#004225] rounded-[5px] w-[100px] h-[50px] text-white"
              //       onClick={(e) => setIsAvailableTimeChange(false)}
              //     >
              //       {t("generic.cancel")}
              //     </button>
              //     <button
              //       className="bg-[#004225] rounded-[5px] w-[100px] h-[50px] text-white"
              //       onClick={SaveTime}
              //     >
              //       {t("generic.save")}
              //     </button>
              //   </div>
              // </div>
            }
            {isAvailableTimeChange && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-[50.666667%] max-h-[80vh] overflow-y-auto p-6 relative">
                  <button
                    className="absolute top-3 right-4 text-gray-500 hover:text-black"
                    onClick={() => setIsAvailableTimeChange(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="flex flex-col gap-6">
                    <p className="text-lg md:text-xl">
                      {t("user_home.add_day_avail_1")}{" "}
                      <span className="text-red-600">
                        {t(transform_timezone(parsedUserProfile?.timezone))}
                      </span>{" "}
                      {t("user_home.add_day_avail_2")}
                    </p>
                    <div className="overflow-x-auto pt-4">
                      <div className="flex space-x-4 justify-center">
                        {dayArray.map((day) => (
                          <div key={day} className="flex flex-col items-center">
                            <WeekDayButton name={day} nowState="" />
                            <div className="flex flex-col space-y-1 pt-2">
                              {hoursArray.map((hour) => {
                                const isInitialSlot = initialBookedSlots.some(
                                  (s) => s.day === day && s.time === hour
                                );
                                const isSelectedSlot = selectedSlots.some(
                                  (s) => s.day === day && s.time === hour
                                );

                                return (
                                  <button
                                    key={`${day}-${hour}`}
                                    onClick={() => toggleSlot(day, hour)}
                                    className={`text-center py-1 px-2 text-md border font-semibold ${
                                      isInitialSlot
                                        ? isSelectedSlot
                                          ? "bg-gray-50"
                                          : "bg-green-900 text-white"
                                        : isSelectedSlot
                                        ? "bg-green-900 text-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    {hour}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-center mt-5">
                        <button
                          className="flex items-center hover:bg-[#005d34] bg-[#004225] rounded-[5px] px-[100px] py-[8px] text-white"
                          onClick={saveTimeSlots}
                        >
                          {t("generic.update")}
                        </button>
                      </div>
                    </div>
                    {/* <div className="flex justify-end gap-4">
                      <button
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                        onClick={() => setIsAvailableTimeChange(false)}
                      >
                        {t("generic.cancel")}
                      </button>
                      <button
                        className="bg-green-700 hover:bg-green-800 text-white px-4 py-2 rounded"
                        onClick={SaveTime}
                      >
                        {t("generic.save")}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            )}

            <div
              id="section4"
              className="flex flex-col items-center gap-2 rounded-2xl mt-6"
            >
              <div className="relative w-full text-center">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                  {t("user_home.my_picture")}
                </span>
              </div>
              {!parsedUserProfile?.image && (
                <p>{t("user_home.upload_picture")}</p>
              )}
              <div className="relative border-[1px] p-1 border-[#000000] w-[120px] h-[120px] md:w-[200px] md:h-[200px] rounded-full flex items-center justify-center">
                {loading || savingImage ? (
                  <Loader />
                ) : (
                  <img
                    src={
                      tempImage ||
                      (parsedUserProfile?.image
                        ? `${parsedUserProfile?.image}`
                        : "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg")
                    }
                    alt="userProfileImage"
                    className="w-full h-full object-cover rounded-full"
                  />
                )}
              </div>

              <div className="flex gap-2 mt-1 md:mt-5">
                <button
                  className="bg-[#004225] rounded-[5px] text-white py-0.5 px-3"
                  onClick={() => fileInputRef.current.click()}
                >
                  {t("generic.edit")}
                </button>
                {tempImage === null && parsedUserProfile?.image && (
                  <button
                    className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                    onClick={deleteImg}
                  >
                    {t("generic.delete")}
                  </button>
                )}
                {tempImage !== null && (
                  <div className="flex gap-2">
                    <button
                      className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                      onClick={saveImage}
                    >
                      {t("generic.save")}
                    </button>
                    <button
                      className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                      onClick={(e) => setTempImage(null)}
                    >
                      {t("generic.cancel")}
                    </button>
                  </div>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
          </TabPanel>
          {/* <TabPanel key={"learnerProfile"} value={"learnerProfile"}>
            <div className="flex flex-col gap-20">
              <ProfileTable
                TABLE_ROWS={userProfile.learner_profiles}
                update={(
                  baseLanguage,
                  baseLanguageSkill,
                  trainingLanguage,
                  trainingLanguageSkill,
                  changeIntroduction,
                  index
                ) =>
                  updateLearner(
                    baseLanguage,
                    baseLanguageSkill,
                    trainingLanguage,
                    trainingLanguageSkill,
                    changeIntroduction,
                    index
                  )
                }
                delete={(index) => deleteLearner(index)}
              />
              <div className="flex justify-center gap-4">
                <button
                  className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                  onClick={() => setAddLearner(true)}
                >
                  Add
                </button>
                <button className="rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white bg-red-400">
                  Delete
                </button>
              </div>
            </div>
          </TabPanel> */}
        </TabsBody>
      </Tabs>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
      <AddLeaderProfile
        open={addLeader}
        close={() => setAddLeader(false)}
        isEditing={isChange !== ""}
      />
      {/* Add a new course, a focus window jumping out. */}
    </div>
  );
}
