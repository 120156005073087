import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import Pagination from "../Pagination/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
  accept,
  complete,
  getCurrentMeeting
} from "../../store/actions/meeting.action";
import { toast } from "react-toastify";
import { getStatusColor } from "../Transformation/Transformations";
import moment from "moment-timezone";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import {
  transform_languages,
  transform_status,
} from "../../components/Transformation/Transformations";
import { transform_timezone } from "../../components/Transformation/Transformations";


export default function Meeting(props) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);

  const getPendingMeetingOfTeacher = useSelector(
    (state) => state.meeting.getPendingMeetingTeacher
  );
  const getCompletedMeetingOfTeacher = useSelector(
    (state) => state.meeting.getCompletedMeetingTeacher
  );
  const getPendingMeetingOfStudent = useSelector(
    (state) => state.meeting.getPendingMeetingStudent
  );
  const getCompletedMeetingOfStudent = useSelector(
    (state) => state.meeting.getCompletedMeetingStudent
  );
  const userProfile = useSelector((state) => state.user.currentUser);
  const userTimeZone = userProfile?.timezone || "UTC";
  // const [loading, setLoading] = useState(false);

  const handleReview = (id) => {
    dispatch(getCurrentMeeting({ token, id, status: "COMPLETED" }));
    navigation(`/meetings/review/${id}`);
  };

  const handleRejectMeeting = (id) => {
    dispatch(getCurrentMeeting({ token, id }));
    navigation(`/meetings/reject_with_note/${id}`);
  };

  const handleCancelMeeting = (id) => {
    dispatch(getCurrentMeeting({ token, id }));
    navigation(`/meetings/cancel_with_note/${id}`);
  };

  const acceptMeeting = (id) => {
    const payload = {
      token,
      id: id,
      status: "CONFIRMED",
    };
    dispatch(accept(payload));
    navigation(`/meetings/accept/${id}`);
  };

  const completedMeeting = (id) => {
    const completedPayload = {
      token,
      id: id,
      status: "WAITING_FOR_REVIEW",
    };
    dispatch(complete(completedPayload));
    toast.success("Meeting completed successfully!", {
      position: "bottom-right",
    });
  };

  const handleCopyLink = (meeting_link) => {
    navigator.clipboard
      .writeText(meeting_link)
      .then(() => {
        toast.success("Link copied to clipboard!", {
          position: "bottom-right",
        });
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("Failed to copy the link.", {
          position: "bottom-right",
        });
      });
  };

  // const [timezone, setTimezone] = useState("");
  let userData = JSON.parse(localStorage.getItem("userData"));
  let timezone = userData?.timezone;

  return (
    <Card className="h-full w-full overflow-scroll border-[#004225] border-[1px] bg-[#F5F5DC]">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none bg-[#F5F5DC]"
      >
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              {t("meeting.meetings")}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {t("meeting.table_text")} <span style={{ color: 'red' }}> {t(transform_timezone(timezone))} </span>
            </Typography>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {props.TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 text-center"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props?.TABLE_ROWS?.map(
              (
                {
                  id,
                  meeting_happen_at,
                  meeting_length,
                  leader,
                  follower,
                  teaching_language,
                  status,
                  meeting_link,
                },
                index
              ) => {
                const isLast = index === props?.TABLE_ROWS?.length - 1;
                let isLeader = userProfile?.id === leader.id;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                const leaderData1 = getPendingMeetingOfTeacher?.results?.find(
                  (userInfo) => {
                    return (
                      userInfo?.leader?.id === leader.id ||
                      userInfo?.follower?.id === leader.id
                    );
                  }
                );
                const leaderData2 = getCompletedMeetingOfTeacher?.results?.find(
                  (userInfo) => {
                    return (
                      userInfo?.leader?.id === leader.id ||
                      userInfo?.follower?.id === leader.id
                    );
                  }
                );

                const leaderUser = leaderData1 || leaderData2;

                const followerData1 = getPendingMeetingOfStudent?.results?.find(
                  (userInfo) => {
                    return (
                      userInfo?.leader?.id === follower.id ||
                      userInfo?.follower?.id === follower.id
                    );
                  }
                );
                const followerData2 = getCompletedMeetingOfStudent?.results?.find(
                  (userInfo) => {
                    return (
                      userInfo?.leader?.id === follower.id ||
                      userInfo?.follower?.id === follower.id
                    );
                  }
                );
                const learnerUser = followerData1 || followerData2;

                const localTime = moment
                  .utc(meeting_happen_at)
                  .tz(userTimeZone);
                const formattedLocalTime = localTime.format(
                  "MMM D, YYYY, h:mm A"
                );

                if (leaderUser || learnerUser) {
                  return (
                    <tr key={id}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {formattedLocalTime}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {meeting_length} min
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {leader.first_name} {leader.last_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {follower.first_name} {follower.last_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-center"
                        >
                          {t(transform_languages(teaching_language))}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          style={{ color: getStatusColor(status) }}
                          className="font-bold text-center text-sm"
                        >
                          {t(transform_status(status))}
                        </Typography>
                      </td>
                      <td className={classes}>
                        {status === "CONFIRMED" && meeting_link != null ? (
                          <div className="flex gap-4 justify-center">
                            <Button
                              className="bg-blue-900 text-white rounded-md"
                              type="button"
                              onClick={() => setIsOpen(true)}
                            >
                              Link
                            </Button>
                            {isOpen && (
                              <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
                                <div className="bg-[#f5f5dc] p-8 rounded-lg shadow-lg max-w-sm w-full text-center">
                                  <p className="mb-4 text-black">
                                    {t("meeting.copy")}
                                  </p>
                                  <p className="mb-4 text-blue-gray-700">
                                    {meeting_link}
                                  </p>
                                  <div className="flex justify-center space-x-4">
                                    <Button
                                      type="button"
                                      color="red"
                                      onClick={() => setIsOpen(false)}
                                    >
                                      {t("generic.cancel")}
                                    </Button>
                                    <Button
                                      type="button"
                                      color="blue"
                                      onClick={() => handleCopyLink(meeting_link)}
                                    >
                                      {t("generic.copy")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="blank-div"></div>
                        )}
                      </td>
                      <td className={classes}>
                        <div className="flex gap-3 justify-center">
                          {!isLeader && status === "WAITING_FOR_REVIEW" && (
                            <Button
                              color="teal"
                              onClick={() => handleReview(id)}
                            >
                              Review
                            </Button>
                          )}

                          {!isLeader && status === "PROPOSED" && (
                            <Button
                              color="red"
                              onClick={() =>handleCancelMeeting(id)}
                            >
                              {t("generic.cancel")}
                            </Button>
                          )}
                          {isLeader && status === "CONFIRMED" && (
                            <Button
                              color="green"
                              onClick={() =>
                                completedMeeting(
                                  id,
                                  meeting_happen_at,
                                  meeting_length,
                                  leader,
                                  follower,
                                  teaching_language
                                )
                              }
                            >
                              Complete
                            </Button>
                          )}
                          {status === "CONFIRMED" && (
                            <Button
                              color="red"
                              onClick={() =>handleCancelMeeting(id)}
                            >
                              {t("generic.cancel")}
                            </Button>
                          )}
                          {isLeader && status === "PROPOSED" && (
                            <>
                              <Button
                                color="green"
                                onClick={() => acceptMeeting(id)}
                              >
                                Accept
                              </Button>
                              <Button
                                color="amber"
                                onClick={() => handleRejectMeeting(id)}
                              >
                                {t("generic.reject")}
                              </Button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              }
            )}
          </tbody>
        </table>
      </CardBody>
      {props.isLoading && (
        <div className="flex items-center justify-center border-t border-blue-gray-50 p-4">
          {<Loader />}
        </div>
      )}
      <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
        {props.TABLE_ROWS && props.TABLE_ROWS?.length > 0 ? (
          <Pagination
            pageNumber={props.numberOfPages}
            setPage={props.setPage}
            page={props.page}
          />
        ) : (
          !props.isLoading && (
            <p className="font-semibold text-lg">No meetings found</p>
          )
        )}
      </CardFooter>
    </Card>
  );
}
