import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  List,
  ListItem,
  ListItemPrefix,
  Drawer,
  Card,
} from "@material-tailwind/react";
import { UserCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";

import { Logout } from "../../store/actions/auth.action";

import MeetingIcon from "../../assets/image/Meeting.svg";
import PaymentHistoryIcon from "../../assets/image/PaymentHistory.svg";
import LogoutIcon from "../../assets/image/Logout.svg";
import ChangePasswordIcon from "../../assets/image/ChangePassword.svg";
import ProfileIcon from "../../assets/image/Profile.svg";
import LeaderProfileIcon from "../../assets/image/Step-3.svg";
import TeamIcon from "../../assets/image/Team.svg";
import LeaderIcon from "../../assets/image/LeaderIcon.svg";
import HowItWorksIcon from "../../assets/image/Howitworks.svg";
import HomeIcon from "../../assets/image/Home.svg";
import Introduction from "../../assets/image/Introduction.svg";
import ScheduleMeetingIcon from "../../assets/image/ScheduleMeeting.svg";
import CourseInfoIcon from "../../assets/image/List.svg";
import MakeWishIcon from "../../assets/image/time-management.svg";
import { useTranslation } from "react-i18next";

export function MenuBar(props) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const removeBodyClass = (className) =>
    document.body.classList.remove(className);
  const logout = () => {
    dispatch(Logout());
    localStorage.removeItem("token");
    localStorage.removeItem("mail");
    localStorage.removeItem("userData");
    props.close();
    removeBodyClass("overflow-hidden");
    navigation("/");
  };
  const { t } = useTranslation();

  const handleClickHome = () => {
    navigation("/");
  };
  const handleClickIntro = () => {
    navigation("/introduction");
  };
  const handleClickLeader = () => {
    navigation("/become-a-leader");
  };
  const handleClickLearner = () => {
    navigation("/become-a-learner");
  };
  const handleClickMeeting = () => {
    navigation("/schedule-meeting");
  };
  const handleClickCourseInfo = () => {
    navigation("/conversation-questions");
  };
  const handleClickWish = () => {
    navigation("/make-a-wish");
  };
  const handleClickTeam = () => {
    navigation("/our-team");
  };
  const handleClickLogin = () => {
    navigation("/login");
  };
  const handleClickRegister = () => {
    navigation("/register");
  };
  const handleClickProfile = () => {
    navigation("/user/profile");
  };
  const handleClickLeaderProfile = () => {
    navigation("/user/leader-profile");
  };
  const handleClickPassword = () => {
    navigation("/user/changePassword");
  };
  const handleMeetingList = () => {
    navigation("/user/meetings");
  };
  const handleClickPayment = () => {
    navigation("/user/payment");
  };
  return (
    <>
      <Drawer
        open={props.open}
        onClose={() => {
          props.close();
          removeBodyClass("overflow-hidden");
        }}
        placement="right"
        className="w-[250px] md:w-[350px] bg-[#F5F5DC] overflow-scroll"
      >
        <Card
          color="transparent"
          shadow={false}
          className="h-[calc(100vh-2rem)] w-full p-4"
        >
          <List
            onClick={() => {
              removeBodyClass("overflow-hidden");
            }}
          >
            <ListItem onClick={handleClickHome}>
              <ListItemPrefix onClick={handleClickHome}>
                <Link>
                  <img
                    src={HomeIcon}
                    alt="Go to home"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.home")}</Link>
            </ListItem>
            <ListItem onClick={handleClickIntro}>
              <ListItemPrefix onClick={handleClickIntro}>
                <Link>
                  <img
                    src={Introduction}
                    alt="introduction"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.introduction")}</Link>
            </ListItem>
            <ListItem onClick={handleClickLeader}>
              <ListItemPrefix onClick={handleClickLeader}>
                <Link>
                  <img
                    src={HowItWorksIcon}
                    alt="Become a leader"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.become_a_leader")}</Link>
            </ListItem>
            <ListItem onClick={handleClickLearner}>
              <ListItemPrefix onClick={handleClickLearner}>
                <Link>
                  <img
                    src={LeaderIcon}
                    alt="Become a learner"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.become_a_learner")}</Link>
            </ListItem>
            <ListItem onClick={handleClickMeeting}>
              <ListItemPrefix onClick={handleClickMeeting}>
                <Link>
                  <img
                    src={ScheduleMeetingIcon}
                    alt="schedule meeting"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.schedule_meeting")}</Link>
            </ListItem>
            <ListItem onClick={handleClickCourseInfo}>
              <ListItemPrefix onClick={handleClickCourseInfo}>
                <Link>
                  <img
                    src={CourseInfoIcon}
                    alt="conversation questions"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.conversation_questions")}</Link>
            </ListItem>
            <ListItem onClick={handleClickWish}>
              <ListItemPrefix onClick={handleClickWish}>
                <Link>
                  <img
                    src={MakeWishIcon}
                    alt="make wish"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.make_wish")}</Link>
            </ListItem>
            <ListItem onClick={handleClickTeam}>
              <ListItemPrefix onClick={handleClickTeam}>
                <Link>
                  <img
                    src={TeamIcon}
                    alt="Our team"
                    className="w-[20px] h-[20px]"
                  />
                </Link>
              </ListItemPrefix>
              <Link>{t("header.our_team")}</Link>
            </ListItem>
            <hr className="my-2 border-green-700 " />
            {token === null ? (
              <>
                <ListItem onClick={handleClickLogin}>
                  <ListItemPrefix onClick={handleClickLogin}>
                    <Link>
                      <UserCircleIcon className="h-5 w-5" />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.login")}</Link>
                </ListItem>
                <ListItem onClick={handleClickRegister}>
                  <ListItemPrefix onClick={handleClickRegister}>
                    <Link>
                      <Cog6ToothIcon className="h-5 w-5" />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.register")}</Link>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem onClick={handleClickProfile}>
                  <ListItemPrefix onClick={handleClickProfile}>
                    <Link>
                      <img
                        src={ProfileIcon}
                        alt="User profile"
                        className="w-[20px] h-[20px]"
                      />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.profile")}</Link>
                </ListItem>
                <ListItem onClick={handleClickLeaderProfile}>
                  <ListItemPrefix onClick={handleClickLeaderProfile}>
                    <Link>
                      <img
                        src={LeaderProfileIcon}
                        alt="Teacher profile"
                        className="w-[20px] h-[20px]"
                      />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.teacher_profile")}</Link>
                </ListItem>
                <ListItem onClick={handleClickPassword}>
                  <ListItemPrefix onClick={handleClickPassword}>
                    <Link>
                      <img
                        src={ChangePasswordIcon}
                        alt="ChangePassword"
                        className="w-[20px] h-[20px]"
                      />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.change_password")}</Link>
                </ListItem>
                <ListItem onClick={handleMeetingList}>
                  <ListItemPrefix onClick={handleMeetingList}>
                    <Link>
                      <img
                        src={MeetingIcon}
                        alt="Meeting"
                        className="w-[20px] h-[20px]"
                      />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.meetings")}</Link>
                </ListItem>
                <ListItem onClick={handleClickPayment}>
                  <ListItemPrefix onClick={handleClickPayment}>
                    <Link>
                      <img
                        src={PaymentHistoryIcon}
                        alt="Payment"
                        className="w-[20px] h-[20px]"
                      />
                    </Link>
                  </ListItemPrefix>
                  <Link>{t("header.payments")}</Link>
                </ListItem>
                <ListItem onClick={logout}>
                  <ListItemPrefix>
                    <img
                      src={LogoutIcon}
                      alt="Logout"
                      className="w-[20px] h-[20px]"
                    />
                  </ListItemPrefix>
                  {t("header.logout")}
                </ListItem>
              </>
            )}
          </List>
        </Card>
      </Drawer>
    </>
  );
}
